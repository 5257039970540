import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import * as queryString from 'query-string';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Layout Imports
import Default from '../Default/Default';
import SEO from '../SEO/SEO';

// Component Imports
import BlogSearch from '../../components/BlogSearch/BlogSearch';
import Pagination from '../../components/Pagination/Pagination';
import Introduction from '../../components/shared/Introduction/Introduction';
import PageSelector from '../../components/shared/PageSelector/PageSelector';
import Circle from '../../components/shared/Circle/Circle';

// Style Imports
import './BlogListing.scss';

// Asset Imports
import Me from '../../assets/images/content/me.svg';

const BlogListing = (props) => {
  const { data, navigate, location } = props;
  const siteTitle = data.site.siteMetadata.title;
  const posts = data.allMdx.edges;
  const localSearchBlog = data.localSearchBlog;
  const { currentPage, numPages, totalPosts } = props.pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? '/blog' : `/blog/${(currentPage - 1).toString()}`;
  const nextPage = `/blog/${(currentPage + 1).toString()}`;
  const { search } = queryString.parse(location.search);

  const handleAnimation = () => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.to('#blog-listing-circle-desktop', {
      scrollTrigger: {
        trigger: '#blog-listing-circle-desktop',
        scrub: 1,
        markers: false,
      },
      rotation: 720,
      ease: 'none',
    });

    gsap.to('#blog-listing-circle-mobile', {
      scrollTrigger: {
        trigger: '#blog-listing-circle-mobile',
        scrub: 1,
        markers: false,
      },
      rotation: 720,
      ease: 'none',
    });
  };

  useEffect(() => {
    handleAnimation();
  }, []);
  
  return (
    <Default
      location={props.location}
      title={siteTitle}
      heading='Blog'
    >
      <SEO title='Blog' />

      <Introduction
        heading='Blog'
        description={search !== undefined ? `Showing results for "${search}"` : `Showing Page ${currentPage} of ${numPages} · Total ${totalPosts} articles`}
        imageSource={Me}
      />

      <PageSelector
        id='mobile-nav-selector'
        triggerText='Blog'
      />

      <div className='blog-listing'>
        <BlogSearch
          currentPage={currentPage}
          posts={posts}
          localSearchBlog={localSearchBlog}
          navigate={navigate}
          location={location}
        />

        <Pagination
          isFirst={isFirst}
          prevPage={prevPage}
          currentPage={currentPage}
          isLast={isLast}
          nextPage={nextPage}
          numPages={numPages}
        />

        {/* Desktop Circles */}
        <Circle
          color='#7E8588'
          height={575}
          width={575}
          top={-230}
          right={-170}
          opacity={0.1}
          desktop
        />

        <Circle
          color='#7E8588'
          height={535}
          width={535}
          top={-230}
          right={-170}
          opacity={0.1}
          thickness={40}
          desktop
        />

        <Circle
          color='#7E8588'
          height={360}
          width={360}
          top={175}
          right={54}
          opacity={0.05}
          thickness={40}
          desktop
        />

        <Circle
          variant='gradient'
          height={576}
          width={576}
          bottom={-215}
          left={-195}
          desktop
          id='blog-listing-circle-desktop'
        />

        {/* Mobile Circles */}
        <Circle
          color='#7E8588'
          height={282}
          width={282}
          top={-180}
          right={-140}
          opacity={0.1}
          thickness={15}
          mobile
        />

        <Circle
          color='#7E8588'
          height={252}
          width={252}
          top={-180}
          right={-140}
          opacity={0.1}
          thickness={30}
          mobile
        />

        <Circle
          color='#7E8588'
          height={235}
          width={235}
          top={40}
          right={-130}
          opacity={0.05}
          thickness={20}
          mobile
        />

        <Circle
          variant='gradient'
          height={311}
          width={311}
          bottom={-90}
          left={-177}
          mobile
          id='blog-listing-circle-mobile'
        />
      </div>
    </Default>
  );
};

export default BlogListing;

export const blogListingQuery = graphql`
  query blogListingQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    localSearchBlog {
      index
      store
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            author
            tags
            path
          }
        }
      }
    }
  }
`;