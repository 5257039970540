import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFlexSearch } from 'react-use-flexsearch';
import * as queryString from 'query-string';

// Component Imports
import ArticleList from '../shared/ArticleList/ArticleList';
import ArticleListItem from '../shared/ArticleList/ArticleListItem';

// Style Imports
import './BlogSearch.scss';

/**
 * Blog post listing and filtering for blog landing page (see src/layouts/BlogListing/BlogListing.jsx)
*/

const BlogSearch = ({
  posts,
  localSearchBlog,
  location,
  navigate,
  currentPage,
}) => {
  const { search } = queryString.parse(location.search);
  
  const [state, setState] = useState({
    search: '',
    query: search || '',
  });

  const results = useFlexSearch(
    state.query,
    localSearchBlog.index,
    JSON.parse(localSearchBlog.store)
  );

  const handleSearch = () => {
    navigate(
      state.search !== '' ? `/blog/${currentPage === 1 ? '' : `${currentPage}/`}?search=${state.search}` : `/blog/${currentPage === 1 ? '' : `${currentPage}`}`
    );
    setState({...state, query: state.search});
  };

  const handleKeyDown = (event) => {
    const code = (event.keyCode ? event.keyCode : event.which);

    if (code === 13) {
      handleSearch();
    }
  };

  return (
    <section className='blog-search'>
      <div className='blog-search__container container'>
        <div className='blog-search__controls'>
          <label className='blog-search__label' htmlFor='search-blog-posts'>Search blog posts:</label>
          <div className='blog-search__input-wrapper'>
            <input
              className='blog-search__input'
              id='search-blog-posts'
              type='search'
              placeholder='Search...'
              onChange={(event) => setState({...state, search: event.target.value})}
              value={state.search}
              onKeyDown={(event) => handleKeyDown(event)}
            />

            <button
              className='blog-search__submit'
              onClick={(event) => {
                event.preventDefault();
                handleSearch();
              }}
              aria-controls='blog-listing'
            >
              <span className='accessible'>Search</span>
            </button>
          </div>
        </div>
        
        {state.query ? (
          <>
            {results && results.length > 0 ? (
              <ArticleList id='blog-listing'>
                {results.map((result, index) => (
                  <ArticleListItem
                    date={result.date}
                    title={result.title || result.slug}
                    description={result.description}
                    slug={result.path}
                    author={result.author}
                    tags={result.tags}
                    key={index}
                  />
                ))}
              </ArticleList>
            ) : (
              <p className='blog-search__warning'>Sorry, couldn't find any posts matching this search.</p>
            )}
          </>
        ) : (
          <ArticleList id='blog-listing'>
            {posts.map((post, index) => (
              <ArticleListItem
                date={post.node.frontmatter.date}
                title={post.node.frontmatter.title}
                description={post.node.frontmatter.description}
                slug={post.node.frontmatter.path}
                author={post.node.frontmatter.author}
                tags={post.node.frontmatter.tags}
                key={index}
              />
            ))}
          </ArticleList>
        )}
      </div>
    </section>
  );
};

BlogSearch.propTypes = {
  /**
   * Array of blog posts to be displayed by default (no search terms)
  */
  posts: PropTypes.array.isRequired,
  /**
   * Blog data
  */
  localSearchBlog: PropTypes.object.isRequired,
  /**
   * The current page location to generate blog post links
  */
  location: PropTypes.object.isRequired,
  /**
   * Function to perform on search term input
  */
  navigate: PropTypes.func.isRequired,
  /**
   * The current blog page number
  */
  currentPage: PropTypes.number.isRequired,
};

export default BlogSearch;