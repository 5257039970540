import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

// Style Imports
import './Pagination.scss';

/**
 * Pagination link list used to navigate between blog listing pages. Used in src/layouts/BlogListing/Bloglisting.jsx.
*/

const Pagination = ({
  isFirst,
  prevPage,
  numPages,
  currentPage,
  isLast,
  nextPage,
}) => {
  // Helper function to create pagination object
  const createPaginationObjects = (length, page, increment = 2) => {
    return Array.from({ length }, (_, i) => ({
      link: `/blog/${i + increment}/`,
      index: i + increment,
      current: page === i + increment,
    }));
  };

  // Create basic pagination link array
  let navItems = [
    {
      link: `/blog`,
      index: 1,
      current: currentPage === 1,
    },
  ];

  // If there are less than 5 pages just display all 5 page links
  if (numPages <= 5) {
    navItems = [
      ...navItems,
      ...Array.from({ length: numPages - 1 }, (_, i) => ({
        link: `/blog/${i + 2}/`,
        index: i + 2,
        current: currentPage === i + 2,
      })),
    ];
  }
  // Else if there's more than 5
  else {
    // If current page selection is in the first three pages:
    // First 3 links
    // Separator
    // Last page link
    if (currentPage <= 3) {
      navItems = [
        ...navItems,
        ...createPaginationObjects(3, currentPage),
        {
          separator: true,
          index: 'starter-separator',
        },
        {
          link: `/blog/${numPages}/`,
          index: numPages,
          current: false,
        },
      ];
    }
    // If current page selection is in the last three pages:
    // First page link
    // Separator
    // Last 3 links
    else if (currentPage > numPages - 3) {
      navItems = [
        ...navItems,
        {
          separator: true,
          index: 'finisher-separator',
        },
        ...createPaginationObjects(4, currentPage, numPages - 3),
      ];
    }
    // If current page selection is not in first or last three pages:
    // First page link
    // Separator
    // Current page link and the one either side
    // Separator
    // Last page link
    else {
      navItems = [
        ...navItems,
        {
          separator: true,
          index: 'starter-separator',
        },
        ...createPaginationObjects(3, currentPage, currentPage - 1),
        {
          separator: true,
          index: 'finisher-separator',
        },
        {
          link: `/blog/${numPages}/`,
          index: numPages,
          current: false,
        },
      ];
    }
  }

  return (
    <nav className='pagination' aria-label='Blog list pagination'>
      <div className='pagination__container container'>
        <ul className='pagination__list'>
          <li className='pagination__list-item'>
            <Link
              className={`pagination__link${isFirst ? ' pagination__link--disabled' : ''}`}
              to={prevPage}
              rel="prev"
            >
              Previous
              <span className='accessible'> page</span>
            </Link>
          </li>

          {navItems.map((item, index) => (
            <li
              className='pagination__list-item'
              key={index}
            >
              {item.separator ? (
                <span className="pagination__ellipsis">
                  &hellip;
                </span>
              ) : (
                <Link
                  className={`pagination__link${item.current ? ' pagination__link--current' : ''}`}
                  to={item.link}
                  aria-current={item.current || undefined}
                >
                  <span className='accessible'>Go to page </span>
                  {item.index}
                </Link>
              )}
            </li>
          ))}

          <li className='pagination__list-item'>
            <Link
              className={`pagination__link${isLast ? ' pagination__link--disabled' : ''}`}
              to={nextPage}
              rel="next"
            >
              Next
              <span className='accessible'> page</span>
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

Pagination.propTypes = {
  /**
   * Check if currently selected page is the first page in the list - returns true/false
  */
  isFirst: PropTypes.bool.isRequired,
  /**
   * Previous page's url slug
  */
  prevPage: PropTypes.string.isRequired,
  /**
   * The total number of pages in the pagination list
  */
  numPages: PropTypes.number.isRequired,
  /**
   * The currently selected page number in the pagination list
  */
  currentPage: PropTypes.number.isRequired,
  /**
   * Check if currently selected page is the last page in the list - returns true/false
  */
  isLast: PropTypes.bool.isRequired,
  /**
   * Next page's url slug
  */
  nextPage: PropTypes.string.isRequired,
};

export default Pagination;